import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlain from './SelectSingleFilterPlain';
import SelectSingleFilterPopup from './SelectSingleFilterPopup';

const SelectSingleCategoryFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <SelectSingleFilterPopup {...rest} />
  ) : (
    <SelectSingleFilterPlain {...rest} />
  );
};

SelectSingleCategoryFilter.defaultProps = {
  showAsPopup: false,
};

SelectSingleCategoryFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleCategoryFilter;
