import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { subcategory } from '../../../config/subcategory';
import { FormattedMessage } from '../../../util/reactIntl';

import IconPlus from '../IconPlus/IconPlus';

import css from './SelectSingleFilterPlain.module.css';
import SelectMultipleFilter from '../SelectMultipleFilter/SelectMultipleFilter';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const { queryParamNames, onSelect, urlParams } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);

    onSelect({ [queryParamName]: option, pub_subcategory: '' });
    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  selectSubCategory(option, param, e, category) {
    const { onSelect, urlParams } = this.props;
    const queryParamName = getQueryParamName(param);
    onSelect({ [queryParamName]: option, pub_category: category });

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      useBorder,
      useHighlight,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.labelSelected : css.label;

    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>{label}</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={!!initialValue} />
              </span>
            </span>
          </button>
        </div>
        <div className={optionsContainerClass}>
          {options.map(option => {
            // check if this option is selected
            const optKey = option.key;
            const selected = initialValue === option.key;
            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                  [css.optionBorderSelected]: selected,
                  [css.optionBorder]: !selected,
                })
              : null;

            const allSubCategory = subcategory.filter(function(el) {
              return el.foriegn == optKey;
            });

            return (
              <div key={optKey}>
                <button
                  className={optionClass}
                  //onClick={() => this.selectOption(option.key)}
                  style={{ fontWeight: 'bold' }}
                >
                  {/* <SelectMultipleFilter
                  id={componentId}
                  label={label}
                  name={name}
                  queryParamNames={queryParamNames}
                  initialValues={initialValues(queryParamNames, liveEdit)}
                  onSubmit={getHandleChangedValueFn(useHistoryPush)}
                  {...config}
                  {...rest} /> */}
                  {useBorder ? <span className={optionBorderClass} /> : null}
                  {option.label}
                </button>

                {allSubCategory[0].config.options.map((o, index) => {
                  return (
                    <button
                      key={o.key}
                      className={optionClass}
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'normal',
                        marginLeft: '10%',
                      }}
                      onClick={event =>
                        index == '0'
                          ? this.selectOption(option.key)
                          : this.selectSubCategory(
                              o.key,
                              allSubCategory[0].queryParamNames,
                              event,
                              option.key
                            )
                      }
                    >
                      {useBorder ? <span className={optionBorderClass} /> : null}
                      {o.label}
                    </button>
                  );
                })}
              </div>
            );
          })}
          <button
            className={css.clearButton}
            onClick={e => {
              this.selectOption(null, e);
              this.selectSubCategory(null, 'pub_subcategory', e);
            }}
          >
            <FormattedMessage id={'SelectSingleFilter.plainClear'} />
          </button>
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default SelectSingleFilterPlain;
