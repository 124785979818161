import React, { useEffect, useState } from 'react';
import { array, bool, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../util/types';
import { ListingCard, PaginationLinks } from '../../../components';
import { gtmEvent, listingToGtagItem } from '../../../analytics/gtm';

import css from './SearchResultsPanel.module.css';
import { useHistory } from 'react-router-dom';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    pathParams,
    setActiveListing,
    isMapVariant,
    onUpdateLike,
    isAuthenticated,
    history,
    currentuser,
  } = props;

  const [isGtmEventSent, setIsGtmEventSent] = useState(false);
  const [firstListingIdOfPage, setFirstListingIdOfPage] = useState(
    listings ? listings[0]?.id.uuid : null
  );

  useEffect(() => {
    if (listings && listings.length !== 0) {
      let isGtmEventSentLocal = isGtmEventSent;
      const firstListingId = listings[0]?.id.uuid;
      if (firstListingIdOfPage !== firstListingId) {
        isGtmEventSentLocal = false;
        setFirstListingIdOfPage(firstListingId);
      }
      if (typeof window !== 'undefined' && !isGtmEventSentLocal) {
        gtmEvent(
          'view_item_list',
          { items: listings.map((el, i) => listingToGtagItem(el, i)) },
          true
        );
        isGtmEventSentLocal = true;
      }
      setIsGtmEventSent(isGtmEventSentLocal);
    }
  }, [listings]);

  let privateData = {};
  if (currentuser != null) {
    if (Object.keys(currentuser).length > 0) {
      privateData = currentuser.attributes.profile.privateData;
    }
  } else {
    privateData.likedListings = JSON.parse(
      (typeof window !== 'undefined' && localStorage.getItem('likedListings')) || '[]'
    );
  }
  const classes = classNames(rootClassName || css.root, className);

  const { location } = useHistory();
  const getPageName = () => {
    if (pathParams.category && pathParams.subcategory) {
      return 'SearchPageSubcategory';
    } else if (pathParams.category) {
      return 'SearchPageCategory';
    } else if (location.pathname.includes('/sale')) {
      return 'SearchPageSale';
    } else {
      return 'SearchPage';
    }
  };
  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={getPageName()}
        pageSearchParams={search}
        pagePathParams={pathParams}
        pagination={pagination}
      />
    ) : null;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  return (
    <div className={classes}>
      {Object.keys(privateData).length > 0 ? (
        <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(isMapVariant)}
              setActiveListing={setActiveListing}
              onUpdateLike={onUpdateLike}
              currentUser={currentuser}
              isAuthenticated={isAuthenticated}
              history={history}
              data={privateData}
            />
          ))}
          {props.children}
        </div>
      ) : (
        <div className={isMapVariant ? css.listingCardsMapVariant : css.listingCards}>
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(isMapVariant)}
              setActiveListing={setActiveListing}
              onUpdateLike={onUpdateLike}
              isAuthenticated={isAuthenticated}
              history={history}
              currentUser={currentuser}
            />
          ))}
          {props.children}
        </div>
      )}
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isMapVariant: true,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isMapVariant: bool,
};

export default SearchResultsPanel;
